import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrl: './input-error.component.scss',
  standalone: true,
})
export class InputErrorComponent {
  @Input({ required: true }) error!: string;
}
